<template lang="pug">
	div.default-modal
		.modal-header
			.modal-title Scan: <span v-if="offer">[{{offer.id}}] {{ offer.title }}</span>
			a.close(href="javascript:void(0);", @click="$emit('close')")
				i.la.la-times
		.modal-body
			div.modal-body-inner
				div(v-if="offerScan")
					div.data-row
						label Status
						.data
							span.badge.badge-light(v-if="offerScan.status===0") New
							span.badge.badge-success(v-if="offerScan.status===1") Success
							span.badge.badge-danger(v-if="offerScan.status===2") Failure
							span.badge.badge-warning(v-if="offerScan.status===3") API Error
					div.data-row
						label Last Update
						.data {{ offerScan.last_scan }}
					div.data-row
						label Type
						.data {{ offerScan.type }}
					div.data-row
						label Params
						.data {{ offerScan.country }} | {{ offerScan.device }}
					div.data-row
						label Redirects
						.data {{ offerScan.redirects }}
					div.data-row
						label Error
						.data {{ offerScan.error }}

			div.ov-table-wrapper(v-if="offerScan")
				table.table.table-striped
					thead
						tr
							th #
							th URL
							th Code
					tbody
						tr(v-for="(r, i) in offerScan.route", v-bind:key="i")
							td {{ i+1 }}
							td
								div.limit-box(style="max-width: 600px; word-break: break-all;") {{ r.url }}
							td {{ r.code }}
				div.mmp(v-if="offerScan.mmp") {{ offerScan.mmp }}

			div.modal-body-inner
				span(v-if="!offerScan")
					p No data was found
				div
					form
						.form-group(v-if="USER.special_account_features", style="width:80px; display: inline-block; padding-right: 8px;")
							label Scanner
							select.form-control(v-model="scanner")
								option(value="affilitest") Affilitest
								option(value="ov-scanner") Internal
						.form-group(v-if="USER.special_account_features", style="width:80px; display: inline-block; padding-right: 8px;")
							label Level
							select.form-control(v-model="level")
								option(:value="0") 0
								option(:value="2") 2
						.form-group(v-if="USER.special_account_features", style="width:80px; display: inline-block; padding-right: 8px;")
							label Pixel
							select.form-control(v-model="pixel")
								option(:value="0") No
								option(:value="1") Yes
						button.btn.btn-secondary(type="button", @click="scan()", :disabled="busy", style="width: 140px;") Scan
							| &nbsp;
							i.la(:class="{'la-search':!busy, 'la-spinner la-spin': busy}")
</template>
<style lang="scss">
.mmp {
  font-weight: bold;
}
.data-row {
  font-size: 12px;
  flex-direction: row;
  display: flex;
  line-height: 24px;
  label {
    width: 200px;
    font-weight: bold;
    text-align: right;
    padding-right: 20px;
  }
  .data {
    flex: 1;
  }
}
</style>
<script>
export default {
  name: 'OfferScanModal',
  props: ['offer', 'externalOffer'],
  data() {
    return {
      offerScan: null,
      scanner: 'affilitest',
      level: 0,
      pixel: 0,
      busy: false,
    };
  },
  computed: {
    USER() {
      return this.$store.state.user;
    },
  },
  methods: {
    async scan() {
      if (this.busy) {
        return;
      }
      const reqParams = {
        offer_id: this.offer ? this.offer.id : null,
        external_offer_id: this.externalOffer ? this.externalOffer.id : null,
        level: this.level ? 1 : 0,
        pixel: this.pixel ? 1 : 0,
        scanner: this.scanner,
      };
      this.busy = true;
      try {
        let resp = await this.$ovReq.post('offer/scan', reqParams);

        this.offerScan = resp.entity;
        if (this.externalOffer) {
          this.externalOffer.offerScan = this.offerScan;
        } else if (this.offer) {
          this.offer.offerScan = this.offerScan;
        }
      } catch (e) {
        console.error(e);
      }
      this.busy = false;
    },
  },
  created() {
    if (this.externalOffer) {
      this.offerScan = this.externalOffer.offerScan;
    } else if (this.offer) {
      this.offerScan = this.offer.offerScan;
    }
  },
};
</script>
