<template lang="pug">
	div.default-modal
		.modal-header
			.modal-title Code
			a.close(href="javascript:void(0);", @click="$emit('close')")
				i.la.la-times
		.modal-body
			.modal-body-inner
				pre
					code(language="json") {{ code | pretty }}
</template>
<script>
export default {
  name: 'CodeViewModal',
  props: ['code'],
  filters: {
    pretty: function (value) {
      return JSON.stringify(JSON.parse(value), null, 2);
    },
  },
};
</script>
